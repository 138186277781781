html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: white;
  overflow: hidden;
}

.App {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 600px;
  transform: translate(-50%, -50%);
}
.App > svg {
  position: relative;
}

.Hand {
  position: absolute;
  width: 2px;
  height: 300px;
  top: 50%;
  left: 50%;
  background-color: black;
  margin-top: -150px;
  transform: rotate(0deg) translate(-50%, -50%);
  transform-origin: 0 150px;
}

.Nav {
  position: fixed;
  top: 0;
  left: 0;
  font-family: sans-serif;
}
.Nav > a {
  display: block;
  padding: 1em;
  cursor: pointer;
}
.Nav > a:before {
  content: "";
  display: block;
  width: 0.5em;
  height: 0.5em;
  background-color: gray;
  border-radius: 50%;
}

.Nav > a:hover:before {
  background-color: black;
}
